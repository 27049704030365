import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Noviembre"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/noviembre"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/noviembre",
        title: "Efemérides - Noviembre | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – NOVIEMBRE</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de noviembre de 1865: Nació en Salta, Ricardo Aráoz. Se graduó de médico
      en la Facultad de Medicina en la Universidad de Buenos Aires. Egresado con
      medalla de plata y 2º premio Facultad, compitiendo méritos con Juan B.
      Justo titular del 1º premio medalla de oro. Fue fundador de la Asistencia
      Pública de la que fue director Honorario durante 20 años. Legislador
      provincial, diputado nacional y ministro de hacienda de la provincia de
      Salta. Presidente del Consejo General de Educación y presidente del Banco
      Provincial de Salta. Falleció en Buenos Aires el 6 de junio de 1950.
    </p>
    <p>
      1 de noviembre de 1913: Se inauguró el servicio de aguas corrientes en
      Cerrillos, provincia de Salta, siendo gobernador don Robustiano Patrón
      Costas. Las obras fueron proyectadas y realizadas por el ingeniero Nolasco
      F. Cornejo.
    </p>
    <p>
      1 de noviembre de 1927: Nació en Salta, Miguel Kortsarz. Profesor de
      Inglés que se desempeñó como Director de la Cultura Británica. Profesor de
      la Universidad Nacional de Salta de Literatura e Inglés Técnico. Docente
      en el Colegio Nacional, Rector en Lenguas Vivas, docente en Literatura e
      Historia. Director de la Universidad Abierta de la Tercera Edad.
    </p>
    <p>
      2 de noviembre de 1840: Nació en San José de Cachi (Salta), Victorino de
      la Plaza. Becado por el general Urquiza. Estudió en el colegio de
      Concepción del Uruguay (Entre Ríos). Obtuvo el doctorado en jurisprudencia
      en la Universidad de Buenos Aires. Viajó a Londres en misión de arreglar
      la deuda externa, cuando era Presidente Carlos Pellegrini, una vez
      concluída su tarea, se radicó allí, ingresando al foro inglés. Fue el
      primer Vice presidente (12 de octubre). En 1913 por enfermedad de Roque
      Saenz Peña asumió provisoriamente la Presidencia, quien finalmente
      falleció, cubrió la acefalía Victorino de la Plaza fue el único mestizo
      que ocupó la Presidencia de la Nación. Llegó al gobierno de Salta su
      biblioteca pública, era una de la más valiosas de su tiempo, en la
      actualidad lleva el nombre “Biblioteca Provincial Dr. Victorino de la
      Plaza”. Falleció en Buenos Aires el 2 de Octubre de 1919.
    </p>
    <p>
      2 de noviembre de 1976: Fueron aprobados los estatutos del Instituto
      Güemesiano de Salta.
    </p>
    <p>
      2 de noviembre de 1985:&nbsp;Falleció el Vicegobernador electo de Salta,
      Dr. Jaime Hernán Figueroa. Fue electo el 30 de octubre de 1983.
    </p>
    <p>
      3 de noviembre de 1790: Nació en Salta Martina Silva. En 1810 se casó con
      José de Gurruchaga. En su casa se hospedó el general Belgrano luego de la
      Batalla de Salta. Bordó y obsequió al Ejército una bandera celeste y
      blanca en vísperas de la Batalla del 20 de Febrero de 1813. Equipó y
      solventó con su dinero al famoso destacamento de ponchos azules, que
      aparecieron en esa batalla por las Lomas de Medeiros. Murió octogenaria en
      Salta, el 5 de marzo de 1873.
    </p>
    <p>
      3 de noviembre de 1952: Nació en Finca La Montaña, San Lorenzo, Prudencio
      Agustín Mamaní. Técnico nacional electromecánico y maestro. Fundador del
      Club Deportivo y Social La Esperanza y organizador de La Liga de San
      Lorenzo y La Caldera. En 1987 resulta electo concejal y presidente del
      cuerpo, llegando a ser Intendente interino. Lleva ya tres elecciones
      ganadas como candidato a intendente, un hombre que trabaja permanentemente
      por el engrandecimiento de su Villa San Lorenzo.
    </p>
    <p>
      3 de noviembre de 1995: El Papa Juan Pablo II recibió al arzobispo de
      Salta, Monseñor Moisés Julio Blanchoud; al de Córdoba, Monseñor Raúl
      Primatesta y al de San Juan, Monseñor Halo Di Stéfano.
    </p>
    <p>
      4 de noviembre de 1944: Falleció en Salta, Calixto Linares Fowlis. Hombre
      de letras. Actuó también en el periodismo. Ganador de los juegos florales
      en 1921 con “Canto al Héroe”. En 1925 publicó “Rimando al Dolor” (poesías)
      no pudo publicar “Frutos de Otoño”.
    </p>
    <p>
      4 de noviembre de 1962: Nació en Salta, Jorge Adrian Gianella, quien
      realiza periodismo cultural, en los medios escritos y radiales. Realizó
      música terapia en Hirpace, como colaborador voluntario.
    </p>
    <p>
      4 de noviembre de 1986: Murió en Salta, Alberto “Nene” Vaca, quien junto a
      Alberto Charango Martínez, actuaban artísticamente, mostrando la música
      argentina, con el nombre: “Duo Martínez-Vaca”.
    </p>
    <p>
      4 de noviembre de 1989: El Sindicato Unico de Trabajadores de Obras
      Sanitarias Salta, inauguró su sede en el inmueble ubicado en la calle
      Catamarca Nº 484 y que fuera adquirida durante la gestión de la segunda
      Comisión Directiva. Esta Comisión estaba integrada de la siguiente forma:
      Secretario General: Pedro Cruz; Secretario Adjunto: Ramón Rojas;
      Secretario Gremial: Luis Ignacio Alvarez; Secretario de Actas: Carlos
      Fernando Díaz; Secretaria Administrativa: Efrencia Brizuela de Aguilar;
      Secretario de Prensa: José Carrasco; Secretario de Acción Social: María D.
      Villalpando de Benegas; Secretario de Viviendas y Obras: Raúl Ismael
      Sombaine; Prosecretario de Hacienda: José René Mollinedo; Vocal Titular
      1º: Oscar Héctor Lacci; Vocal Titular 2º: Miguel Alberto Burgos; Vocal
      Titular 3º: Gerónimo Ricardo García.
    </p>
    <p>
      5 de noviembre de 1965: Se fundó en la ciudad de Salta la Asociación de
      Protesistas Dentales de Laboratorio. La primera comisión directiva estuvo
      presidida por José Cabello como presidente y Oscar Monterichel como
      vicepresidente.
    </p>
    <p>
      6 de noviembre de 1892: Falleció Juana Manuela Gorriti a los 91 años. Se
      casó con el Presidente de Bolivia general Belzú. Es considerada la primera
      novelista argentina. Nació en Horcones, Metán en 1816. Algunas de sus
      obras más importantes: La Quena, Sueños y realidades, Güemes, El Pozo de
      Yocci, Carmen Puch, Guby Amaya, Vida militar y política del general
      Dionisio Puch, El mundo de los recuerdos, La tierra natal, El tesoro de
      los Incas, El Chifle del Indio, El poster mandato, Una ojeada a la patria,
      La novia del muerto, Una noche de agonía, Panorama de la vida, La cocina
      ecléctica, Oasis en la vida, La alborada del Plata y muchas otras. El
      Senado de la Nación editó su obra completa en tres tomos.
    </p>
    <p>
      6 de noviembre de 1943: Nació en Salta, Gustavo Uldry, quien se desempeñó
      como presidente de la Cámara Regional de la Producción y de otras
      entidades vinculadas con la actividad rural.
    </p>
    <p>
      6 de noviembre de 1959: Falleció Juan Carlos Dávalos. Poeta y escritor
      Salteño. Publicó en poesía: De mi vida y de mi tierra, Cantos Agrestes,
      Canto de la Montaña, Salta. En Prosa: El viento blanco, Los casos del
      zorro, Airampo, Salta, Aguila renga, Los gauchos, Los buscadores de oro,
      Los valles de Cachi y Molinos, La Venus de los Barriales, Estampas
      Lugareñas, Ensayos biológicos y otras.
    </p>
    <p>
      6 de noveimbre de 1965:&nbsp;Visitaron Salta, los Reyes de Bélgica,
      Balduino y Fabiola. Llegaron impulsados por su devota fe hacia el Señor y
      la Virgen del Milagro. Era el gobernador de Salta el doctor Ricardo
      Joaquín Durand. El 7 de noviembre se realizó en la Catedral una misa a
      cargo de monseñor Pedro Lira, en homenaje a los visitantes.
    </p>
    <p>
      7 de noveimbre de 1810: Al frente de salteños y tarieños, Güemes venció en
      la batalla de Suipacha a las fuerzas mandadas por el general Córdoba. El
      general derrotado retrocede hacia Cotagaita donde arrastra en su huida a
      su jefe, el general Nieto, dejando libre la ruta del Alto Perú, al
      ejército enviado por la Junta de Mayo.
    </p>
    <p>
      7 de noviembre de 1832: Los coroneles Pablo Latorre y Pablo Alemán,
      derrotaron a los salteños revolucionarios encabezados por los coroneles
      Cruz Puch y Napoleón Güemes, en Pulares, provincia de Salta.
    </p>
    <p>
      7 de noveimbre de 1843: Nació en Salta, Eliseo F. Outes. Abogado,
      periodista de “Democracia” y “La Reforma”. Diputado Nacional por Salta.
      Ministro de Hacienda. Vice Rector del Colegio Nacional de Salta. Murió el
      8 de noviembre de 1905.
    </p>
    <p>
      7 de noviembre de 1933: Murió Joaquín Corbalán en Buenos Aires. Recibió
      título de médico en la Universidad de Buenos Aires. Miembro del Consejo
      Provincial de Higiene. Vocal del Consejo General de Educación y Médico de
      Tribunales. Gobernador de la Provincia de Salta desde el 1º de mayo de
      1925 hasta el 1º de mayo de 1928.
    </p>
    <p>
      7 de noviembre de 1942: Nació en Cafayate, Roque Mario Mangini. Hijo de
      padres europeos, este empresario incursionó con una granja modelo en la
      cría de aves y de cerdos. Posee 4 fábricas de pan. Es el fundador y
      propietario del Colegio Américo Vespucio de la ciudad de Salta, que brinda
      enseñanza, primaria, secundaria y terciaria. Trabaja en el proyecto de una
      Facultad de Medicina Privada. Es uno de los directivos de la Fundación
      NOA.
    </p>
    <p>
      7 de noviembre de 1949: Se inauguró la Escuela de Educación Técnica Nº 13
      San Ignacio de Loyola de Cerrillos. Antes se llamaba Escuela de
      Manualidades de Cerrillos, bajo la dirección de Azucena Blanca Etchart.
    </p>
    <p>
      7 de noviembre de 1958: Nació en Salta, Marcelo Armando Hoyos. Profesor de
      Historia, Periodista Deportivo, Jefe de Prensa de FM Lasser.
    </p>
    <p>
      7 de noviembre de 1993: Falleció el empresario Roberto Briones. Fue
      presidente de la Cámara de Comercio Exterior y propulsor de Ferinoa.
      Trabajó incansablemente por la integracón de la Región. Fue miembro de
      Geicos.
    </p>
    <p>
      8 de noviembre de 1753: Nació en Molinos (Salta), Nicolás Severo de
      Isasmendi Echalar. En 1776 por orden del gobernador Don Jerónimo de
      Matorras, dirigió la represión contra los Atacamas, que se habían
      sublevado contra los españoles en solidaridad con la rebelión dirigida por
      Tupac Amaru extendida en casi toda América Meridional. Fueron reconocidos
      sus servicios, recibiendo en premio el grado de capitán. Fue comandante
      del Regimiento de Caballería con asiento en Salta. El 14 de mayo de 1809
      recibió del Virrey Liniers la designación de Gobernador-Intendente de
      Salta. El Cabildo adhirió a la Primer Junta de Buenos Aires en la sesión
      del 19 de junio de 1810. Isasmendi reaccionó y mando apresar a todos los
      cabildantes, logró fugarse don Calixto Gauna y en histórico galope de 8
      días llevó la comunicación de lo sucedido a las autoridades en Buenos
      Aires. El 23 de agosto de 1816. Su cadáver momificado y en sarcófago de
      vidrio se conserva en la iglesia de Molinos como testimonio del último
      gobernador realista salteño.
    </p>
    <p>
      8 de noviembre de 1866: Nació en Salta, Ricardo Mendioroz. Periodista.
      Trabajó en los diarios “La Mañana”, “El Nacional”, “La Provincia” y en “La
      Gaceta”. Fundó y dirigió la “Revista de Letras y Ciencias Sociales”. En
      Buenos Aires dirigió el diario “Provincias Ilustradas”. Falleció el 4 de
      febrero de 1908.
    </p>
    <p>
      8 de noviembre de 1896: Nació en Salta, Ricardo N. Mesone. Abogado.
      Secretario de Gobierno. Diputado Provincial. Murió en Buenos Aires el 1º
      de julio de 1951.
    </p>
    <p>
      9 de noviembre de 1835: Falleció José Ignacio de Gorriti. Militar y
      abogado. Padre de Manuela Gorriti. Colaboró con Güemes. Luchó por la
      Independencia. Fue gobernador de Salta. Firmó el acta de la Independencia.
    </p>
    <p>
      9 de noviembre de 1859: Se dió el Decreto Gubernativo que decía: “Queda
      instituido en el Departamento de la Provincia el nuevo Curato de San José
      de Metán bajo la misma denominación y límites que como curato le tenían
      asignado y con el goce de derechos y prerrogativas que la ley acuerda al
      Departamento”.
    </p>
    <p>
      9 de noveimbre de 1891: Murió en Buenos Aires, el abogado José María
      Zuviría. Nació en Salta en 1830. Se graduó de abogado en 1852. Hijo de
      Facundo de Zuviría. Fue secretario del Congreso Constituyente de Santa Fe
      en 1853. Redactó la Constitución Provincial de Catamarca. Representó a
      Santiago del Estero ante el Congreso como diputado nacional. En 1875
      publicó el libro de poemas: El peregrino de Plata. En 1890 “Obras
      poéticas”. En prosa publicó: “Presidencia de Sarmiento” y “Los
      Constituyentes”.
    </p>
    <p>
      9 de noveimbre de 1892: Nació en Rosario de la Frontera, Salta, Carlos S.
      Fernández Cornejo. Realizó los estudios primarios y secundarios en Salta,
      Capital. Tenía dotes naturales para la literatura. Muy amigo de Martín
      Güemes Castro, nieto del héroe. Joaquín Castellanos lo vinculó con los
      círculos literarios de Buenos Aires. Publicó prosa y en verso. Sus obras
      más conocidas: Tribulaciones de un ex ministro”, “Ciro Anzoátegui, el
      Gaucho popular del Norte” (obras en prosa). En verso: “A la bandera” y
      “Canto al tabacal”. Murió en Salta el 9 de agosto de 1941.
    </p>
    <p>
      9 de noviembre de 1906: Nació el doctor Demetrio Ernesto Herrera. Fue
      Senador Provincial desde el 29 de julio de 1963 hasta el 30 de abril de
      1965. Fue un gran propulsor del deporte de Salta, por más de 50 años.
      Falleció el 4 de setiembre de 1987.
    </p>
    <p>
      9 de noviembre de 1995: Llegó al aeropuerto El Aybal de Salta, a horas
      19,30 Miguel Najdorf, procedente de Buenos Aires. Vino a presenciar los
      finales el Circuito Nacional de Ajedrez, Clarín 1995, 6ª Etapa, Copa Norte
      Grande.
    </p>
    <p>
      10 de noviembre de 1942: Nació en Salta, Julio Oscar León. Egresó como
      Escultor de la Escuela Tomás Cabrera. Tuvo a su cargo la restauración de
      los murales de la iglesia de San Francisco, de la imagen de la Iglesia de
      Payogasta. Dibujante de la Casa de la Cultura.
    </p>
    <p>
      10 de noviembre de 1944: Murió en Capital Federal, Natal López Cross.
      Nacido en Salta en 1882. Médico. Se desempeñó en el Hospital del Milagro
      donde realizó una intervención quirúrgica suturando el corazón de un
      hombre al que le fuera atravesado el ventrículo derecho con un estilete,
      con lo que el paciente pudo sobrevivir. La prensa comentó el tema como el
      primero practicado en Sud América y el profesional salteño fue considerado
      como una de las figuras más destacadas de la ciencia médica.
    </p>
    <p>
      11 de noviembre de 1877: Falleció en Salta, Fortunata de la Corte de Peña.
      Esta patricia, juntamente con otras damas salteñas, colaboró en la
      preparación de uniformes para los soldados de los ejércitos patriotas.
      Cooperó con Belgrano, con Rondeau y con Güemes.
    </p>
    <p>
      11 de noviembre de 1975:&nbsp;Comenzó en Salta, la conmemoración de la
      llegada de los salesianos a la República Argentina, cien años antes.
    </p>
    <p>
      12 de noviembre de 1691: Asumió el gobierno de Salta del Tucumán, don
      Martín de Jáuregui.
    </p>
    <p>
      12 de noviembre de 1826: Fecha de la fundación de Cafayate, hoy cabecera
      del departamento que lleva el mismo nombre. Es una región que produce
      buenos vinos, reconocidos a nivel internacional. Famosa es su Serenata a
      Cafayate, festival al que concurren los mejores intérpretes argentinos.
      Desde su origen fue animada por el poeta César Fermín Perdiguero; desde
      hace más de 10 años se hizo cargo de la conducción el poeta Eduardo
      Ceballos. Es una región muy visitada por el turismo.
    </p>
    <p>
      12 de noviembre de 1930: Falleció en Salta, Eduardo Fernández, a los 148
      años de edad. El día 14 el diario “La voz del Norte”, anunciaba la muerte
      del abuelo de Salta. Era oriundo de Bolivia, jornalero, con residencia en
      General Güemes.
    </p>
    <p>
      13 de noviembre de 1887:&nbsp;El Consejo Municipal de Metán prohibió por
      Ordenanza la circulación de todo billete, oficial o particular que no sean
      la moneda nacional de curso legal o los emitidos por el Banco de la
      Provincia.
    </p>
    <p>
      13 de noviembre de 1930: Nació en Salta, César Antonio Alurralde. Poeta y
      escritor que logró importantes premios. Publicó en libro: “Nubes al
      garete”, “La casa de los sueños y pájaros del alba”, Diccionario del vino.
      Colabora con periódicos y revistas especializadas. También publicó,
      Cuentos Breves, Los Nadies, Historial del Instituto Provincial de Seguros
      de Salta, Los Carenciados. Trabaja en la edición de una decena de nuevos
      libros.
    </p>
    <p>
      13 de noviembre de 1963: Nació en Salta, Mario Rolando Teruel. Es barítono
      y primera guitarra del consagrado conjunto salteño, “Los Nocheros”.
    </p>
    <p>
      14 de noviembre de 1801: Nació en Salta, Serapio Ovejero, militar desde
      los 12 años. Acompañó al Héroe Gaucho en su marcha sobre Tucumán. Tomó
      parte en distintos combates. Falleció en San Juan el 1º de julio de 1878.
    </p>
    <p>
      14 de noviembre de 1822: El Gobernador de Salta, Dr. José Ignacio Gorriti,
      se trasladó con un numeroso séquito hasta la capilla del Chamical. Dispuso
      que los restos del General Güemes fueran retirados del lugar y los condujo
      con su comitiva, hasta la Antigua Catedral de Salta.
    </p>
    <p>
      14 de noviembre de 1833: Nació en Salta, Juan Martín Leguizamón. Diputado
      Provincial en 1852. Estudió sobre fósiles calchaquíes, con los que habría
      de aportar importantes conocimientos a los investigadores de la época como
      Ambrosetti, Ameghino y otros que lo citan en sus obras. Fue Ministro de
      Hacienda. Fue Senador Nacional. Fue el más grande propulsor de la
      enseñanza y el mayor científco salteño de su época. Se lo considera como
      el creador del Consejo General de Educación -organismo del que fue su
      primer presidente-. Fundador de la Biblioteca Pública de la provincia, a
      la que aportó su biblioteca particular. Autor de “Estadística de la
      Instrucción Primaria de Salta”. “Cartas de Antigüedades Americanas”,
      “Cuestión de límites de Argentina y Chile”, “Apuntes históricos sobre
      Salta”, “L” Homme blanche en 1877 (premio, medalla y diploma de honor en
      París). Falleció en Buenos Aires el 30 de julio de 1881.
    </p>
    <p>
      14 de noviembre de 1852:&nbsp;Nació en Salta, Clodomiro Arce. Se doctoró
      en derecho. Graduándose de maestro y licenciado en Teología, hacia fines
      de 1877. En 1878 recibió las órdenes sagradas de manos del Obispo Rizo
      Patrón. Fue rector del Seminario Conciliar de Salta. Dirigió el diario
      “Democracia”. Al iniciarse el año 1900 y conmemorado la llegada del siglo
      XX, hizo construir y transportar con ayuda de los “Josefinos”, la conocida
      cruz que capea desde la cumbre del cerro “San Bernardo”. Murió el 27 de
      setiembre de 1909.
    </p>
    <p>
      14 de noviembre de 1855: Nació en Salta, Valentín Delgadillo. Se graduó de
      médico en 1867. Se radicó en Tandil hasta su muerte. Murió el 28 de
      setiembre de 1881, habiendo logrado al respeto y la estima de todo el
      vecindario.
    </p>
    <p>
      14 de noviembre de 1863: Se elevó el partido de Cafayate al rango de
      Departamento, en la provincia de Salta.
    </p>
    <p>
      14 de noviembre de 1931: Nació en Salta, José Antonio Gutiérrez. Se
      recibió de Bioquímico en la Universidad Nacional de Tucumán. Fue docente
      en la Universidad Nacional de Tucumán y en entidades educativas de Salta.
      Ocupó importantes cargos en Salud Pública. Asistió a Jornadas, Congresos,
      Reuniones científicas. Organizó cursos de su especialidad y realizó cursos
      de perfeccionamiento. Integró comisiones oficiales de trabajo y actuó en
      Sociedades Científicas y Gremiales. Obtuvo becas y distinciones, por sus
      tareas profesionales.
    </p>
    <p>
      15 de noviembre de 1807: Falleció Gabriel de Güemes y Montero a los 59
      años de edad, padre del héroe Martín Miguel de Güemes.
    </p>
    <p>
      15 de noviembre de 1952: Nació en Salta, Sara Mamaní. Desde niña se
      destacó con la música, ya que a los 12 años, ganó el primer premio de un
      Concurso Infantil para Escuelas Primarias en canto. Perteneció a la
      Embajada Artística de diario El Tribuno. En 1970, representó a Salta en el
      Festival de Cosquín, donde interpretó La Pomeña. En 1984 hizo música en el
      Teatro Negro en Buenos Aires. Fundadora del grupo ALLAQUI, que está
      integrado por Sara Mamaní, la Negra Chagram Gabriel Redin y Sergio Suárez.
      Con Allanqui tiene grabaciones realizadas y actuan por todo el país.
    </p>
    <p>
      15 de noviembre de 1958: Nació en Orán, Raúl Juan Reynoso. Abogado y
      poeta. Publicó: Versos de Amor por una muchacha enamorada.
    </p>
    <p>
      16 de noviembre de 1814: Falleció en Buenos Aires, Juan Luis Aguirre y
      Tejeda. Nació en Salta alrededor de 1760. Realizó sus estudios en Córdoba.
      Fue maestro y licenciado en filosofíay doctor en teología. Se trasladó al
      Alto Perú, a fin de cursar jurisprudencia en la Universidad de San
      Francisco Javier, de la ciudad de Chuquisaca, donde obtuvo la graduación
      de Abogado y la correspondiente matrícula en la Real Audiencia de Charcas,
      en el año 1779. Volvió a Córdoba, ejerció cargos oficiales. Colaboró con
      la Junta de Gobierno de Buenos Aires.
    </p>
    <p>
      16 de noviembre de 1891: Falleció en Metán, Aniceto Latorre. Enfrentó al
      gobernador General José Manuel Saravia el 3 de marzo de 1853, derrocándolo
      por pretender perpetuarse del poder en detrimento del primer gobernador
      Constitucional don Tomás Arias. Fue nombrado comandante General de
      Milicias con asiento en Salta. Radicado en Mendoza obtuvo el grado de
      general e integró el Consejo de Guerra que juzgó al General Arredonde y
      demás promotores del alzamiento de 1874.
    </p>
    <p>
      16 de noviembre de 1950: Nació en Salta, Juan Carlos Romero. Hijo de
      Roberto Romero y Vicenta Di Gangi. Casado con Carmen Lucía Marcuzzi,
      matrimonio del que nacieron 4 hijos: Juan Esteban, Bettina Inés, Lucía del
      Milagro y Mercedes Victoria. Realizó sus estudios primarios en el colegio
      Belgrano y en la escuela Justo José de Urquiza de Salta. Los estudios
      secundarios en el Bachillerato Humanista Moderno de Salta, de donde egresó
      en 1968. En 1974 recibió el título de abogado en la Facultad de Derecho de
      la Universidad Nacional de Buenos Aires. Además se recibió de piloto
      civil. Estudió inglés en Estados Unidos. Cursó el doctorado de Ciencias
      Políticas en la Facultad de Derecho de la Universidad Nacional de Buenos
      Aires. En Estados Unidos y Uruguay estudió redacción electrónica y
      periodismo moderno. Fue profesor adjunto de la cátedra Derecho Comercial I
      en la Universidad Católica de Salta, entre 1976 y 1986 presidente de esa
      entidad. En cuanto a la actividad política, en 1985, fue electo
      convencional constituyente. Presidente de la Comisión Redactora de la
      Asamblea Constituyente anta la reforma de la Construcción de Salta, desde
      el 3 de enero de 1986 hasta concluir su cometido el 2 de junio de 1986.
      Miembro además de las comisiones de derechos individuales y comunicaciones
      sociales de la mencionada asamblea. En 1986, fue Congresal Provincial del
      Partido Justicialista por el departamento capital. En 1987, Congresal
      Nacional del Partido Justicialista. Desde 1986 a 1995 Senador Nacional por
      la provincia de Salta. Desde 1986 a 1989, presidente de la Comisión de
      Economía del Honorable Senado de la Nación. En 1989 fue designado para
      integrar el comité de seguimiento del tratado celebrado entre la República
      Argentina y la República de Italia. A partir del 17 de marzo de 1991,
      miembro del Consejo Provincial del Partido Jusiticialista de Salta.
      Presidente del Congreso Provincial del Partido Justicialista de Salta.
      Desde el 8 de noviembre de 1991, vicepresidente primero Consejo provincial
      del Partido Justicialista de Salta. Designado miembro del Consejo Nacional
      de Partido Justicialista de Salta. En octubre de 1992, renuncia al mandato
      de senador nacional para el período 1986-1995, siendo electo para el
      período 1992-2001. Es el candidato a gobernador de Salta para elecciones
      de 1995 del Partido Justicialista, que resultó electo para gobernador de
      la provincia a partir del 10 de diciembre de 1995.
    </p>
    <p>
      16 de noviembre de 1950: Nació en Salta, Fernando García Bes. Doctor en
      Ciencias Veterinarias, político, estudioso y cultor de la antropología
      folklórica y de la canción nativa. Publicó en ediciones compartidas: Canto
      a Cabra Corral; Poesía y Folklore; Canto a Todos (con Jaime Arturo
      Dávalos); Insomnios Testomoniales (Agosto de 1995). Este último libro fue
      presentado en el marco de Ferinoa 95. Conductor de programas radiales y
      autor de temas folklóricos.
    </p>
    <p>
      16 de noviembre de 1976: Bomberos Voluntarios “Martín Miguel de Güemes” de
      Salta, se adhiere a la Confederación Argentina de Bomberos Voluntarios.
    </p>
    <p>
      17 de noviembre de 1838: Felipe Heredia delegó el gobierno de la provincia
      de Salta, en una Comisión Gubernativa integrada por Juan Manuel Quiroz y
      Manuel Solá Torino.
    </p>
    <p>
      17 de noviembre de 1858: Creóse el Departamento de La Viña de Guachipas,
      en la provincia de Salta.
    </p>
    <p>
      17 de noviembre de 1866: Nació en Finca Las Moras o El Dátil, en el
      Departamento de La Candelaria, provincia de Salta, Dolores Lola Mora. En
      homenaje a su natalicio este día fue instituido como Día Nacional del
      Escultor y de las Artes Plásticas. Triunfadora con su arte en el país y en
      Europa. Sus obras más importantes son: “La Fuente de las Nereidas”, Los
      grupos escultóricos para el frente del Congreso de la Nación; los bajos
      relieves de la Casa de la Independencia de Tucumán; monumento a Juan
      Bautista Alberdi, estatua al general Alvear, estatua al Dr. Facundo de
      Zuvíria entre otras.
    </p>
    <p>
      17 de noviembre de 1922: Se fundó la Biblioteca Popular “General Güemes”
      en Campo Quijano, provincia de Salta.
    </p>
    <p>
      17 de noviembre de 1988: Este día se inició II Semana Tecnológica del
      Norte Grande, en la ciudad de Salta. Dedicada enteramente al
      Aprovechamiento Integral del Río Bermejo.
    </p>
    <p>
      18 de noviembre de 1834: Jujuy fue separada de Salta, constituyéndose de
      este modo la creación de una nueva provincia argentina. Los jujeños
      reunidos en la Plaza Mayor, votaron la independencia política de la
      ciudad, campaña y territorio, de Salta.
    </p>
    <p>
      18 de noviembre de 1995: Se cerró la estación ferrovaria de Tartagal. Se
      llamaba Manuela Pedraza. Su último jefe fue Florindo Correa.
    </p>
    <p>
      19 de noviembre de 1831: Nació en Salta, el doctor José Evaristo Uriburu,
      quien fue presidente de los Argentinos.
    </p>
    <p>
      19 de noviembre de 1939: Nació en Los Blancos, departamento de Rivadavia,
      Fausto Ponciano Machuca, maestro nacional, que fue senador provincial por
      tres períodos desde 1983 hasta 1995. En 1991 fue Ministro de Bienestar
      Social y en la actualidad es el Secretario de Derechos Humanos, cargo de
      la provincia de Salta.
    </p>
    <p>
      20 de noviembre de 1870: Murió en Rosario de la Frontera (Salta), Manuel
      de Puch. Combatió en el triunfo de Salta, el 20 de febrero de 1813.
      Colaboró con el General Güemes (su cuñado). Combatió contra Facundo
      Quiroga y Felipe Varela.
    </p>
    <p>
      20 de noviembre de 1906: Asumió nuevamente como gobernador de Salta, don
      Angel Zerda.
    </p>
    <p>
      20 de noviembre de 1917: Se firmó contrato de concesión para la
      instalación y explotación de una usina eléctrica para Orán. Por la empresa
      firmaron los señores David López y Fernando Torres y por el municipio don
      Segundo Luna.
    </p>
    <p>
      20 de noviembre de 1928: Se fundó en la ciudad de General Güemes, la
      Biblioteca Popular Domingo Faustino Sarmiento, que hoy se ubica frente a
      la Plaza principal.
    </p>
    <p>
      21 de noviembre de 1892: Nació en Salta, Abel F. Cornejo. Egresó de la
      Universidad Nacional de Buenos Aires, de Ingeniero Civil. Se desempeñó en
      la Administración de Vialidad Nacional. Autor y ejecutor de diversos
      proyectos como: el camino de la “Cuesta del Obispo”, el camino de la
      “cornisa” a Jujuy, la ruta Alemanía-Cafayate y otros. Fue docente.
      Escribió un libro: “Ayuda Federal a la Provincias”, que mereció diploma de
      honor en el III Congreso Argentino de Vialidad. Murió el 29 de junio de
      1959.
    </p>
    <p>
      21 de noviembre de 1948: Nació en Salta, Horacio Patrón Costas. Abogado
      que desempeña importantes tareas sociales. Fue gerente de la Sucursal
      Salta del Banco Comercial del Norte; Gerente Comercial del Banco Regional
      del Norte, Presidente de Cortesa; Vicepresidente de TEVEFE; Diretor de
      Televisoras Provinciales S.A. y Cableadoras Provinciales S.A.; presidente
      del Club 20 de Febrero (3 períodos); Presidente del Proagro S.A.,
      Centinela S.A.; El Raigón S.A. y COPACO S.A. (Producción Agropecuaria);
      Vicepresidente y Secretario de la Unión Industrial de Salta (VIS) y
      Representante ante la Unión Industrial Argentina de Clubes Sociales.
    </p>
    <p>
      22 de noviembre de 1922: Nació en El Carmen, provincia de Jujuy, Luis
      Clemente D´Jallad Ortiz. Salteño por adopción. Ejerció el periodismo
      durante muchos años en diario El Tribuno, El Intransigente y en otras
      publicaciones. Publicó: “El Canto a la Cruz” en 1947 y en 1954 la Comisión
      Provincial de Cultura de Jujuy Poeta autor de Canto al Inmigrante y Canto
      al Gaucho. Fue premiado por su Himno a Orán. Fue intendente de la ciudad
      de Salta.
    </p>
    <p>
      22 de noviembre de 1925: Nació Adán Galli. Ingeniero Civil que ocupó
      importantes funciones públicas como Director del Banco Provincial de
      Seguros, Intendente de la ciudad de Salta. Como poeta publicó tres libros
      de poemas. Sus dos últimos títulos: “Las Rosas de mi Jardín” (1995); “El
      Perfumado Abecé de mis Rosas”).
    </p>
    <p>
      22 de noviembre de 1992: En esta jornada culminaron los VII Juegos
      Deportivos y Recreativos Especiales Provinciales, que se habían iniciado
      el 20 de noviembre. Fueron organizados por la Dirección de la Escuela Nº
      400 de Educación Especial doctor Julio Augusto Cintioni y la Dirección de
      Deportes Municipal de General Güemes, de la provincia de Salta.&nbsp;
      <br />
      22 de noviembre de 1995: La Asamblea Legislativa elegió Senador Nacional
      por Salta, al Contador Público Nacional Emilio Marcelo Cantarero.
    </p>
    <p>
      23 de noviembre de 1782: Nació en Salta, Juan Saturnino Castro. Fue uno de
      los pocos nativos que pelearon durante las guerras de la independencia por
      la causa realista. Castro se definió por la causa del rey. Habiendo sido
      liberado después de la rendición de Pío Tristán con promesa de no tomar
      las armas contra las Provincias Unidas, Castro fue uno de los que combatió
      contra las fuerzas patriotas en Vilcapugio y su intervención fue decisiva
      en el resultado por su conocimiento extraordinario del terreno. Su hermano
      Manuel Antonio de Castro y otros lo ganaron para la causa de la
      emancipación Americana. Fue así que planeó una sublevación de una parte
      del ejército realista contra el General Pezuela, enterado éste por una
      delegación, lo hizo apresar y luego de un juicio ordenó su ejecución. Fue
      fusilado en Moraya (hoy Bolivia) el 1 de setiembre de 1814.
    </p>
    <p>
      23 de noviembre de 1934: Se inició la construcción del Correo de Salta.
    </p>
    <p>
      23 de noviembre de 1941: Nació en Metán, Rogelio Aguilar. Artesano de
      madera y pintor. Enmarca sus pinturas en bellos marcos artesanales
      realizados por él mismo.
    </p>
    <p>
      23 de noviembre de 1974:&nbsp;Asumió como Interventor de la provincia de
      Salta, José Alejandro Mosquera.
    </p>
    <p>
      24 de noviembre de 1877: Se sancionó la Ley Orgánica de Municipalidades de
      la provincia de Salta.
    </p>
    <p>
      24 de noviembre de 1961: Falleció en Salta, el odontólogo José Vicente
      Solá. Fue docente en el Colegio Nacional. Ocupó la Presidencia de la
      Cámara de Diputados de la provincia de Salta. Se dedicó al estudio de la
      lengua castellana. Publicó: Gramática Castellana, Castellano, Curiosidades
      Gramaticales, Contribución del árabe a la formación de la lengua
      castellana o española y su reconocida obra “Diccionario de Regionalismo de
      Salta”, que fue premiado por la Comisión Nacional de Cultura”.
    </p>
    <p>
      24 de noviembre de 1986:&nbsp;Asumió, como director del Colegio Secundario
      “Juan Calchaqui”, el profesor Francisco Fernández, en la ciudad de Salta.
      Este Colegio se creó por iniciativa del profesor Miguel Angel Cáseres,
      quien trabajó junto a toda su barriada.
    </p>
    <p>
      24 de noviembre de 1994: Falleció en Jujuy, Aristóbulo Wayar, destacado
      periodista, que actuó en la ciudad de Salta y en Jujuy.
    </p>
    <p>
      25 de noviembre de 1817: El coronel Manuel Eduardo Arias, perteneciente al
      ejército de Güemes, derrotó a los realistas mandados por el brigadier José
      Antonio de Olañeta, en Colanzulí, provincia de Salta.
    </p>
    <p>
      25 de noviembre de 1901: Salta cede a la Nación el pueblo de San Antonio
      de los Cobres y una gran porción de la puna Salteña, con la que se forma
      el territorio nacional de Los Andes, como consecuencia de la rectificación
      de los límites con Chile.
    </p>
    <p>
      25 de noviembre de 1946: Nació en Salta, Gregorio Caro Figueroa. Escritor,
      ensayista, periodista. Publicó: “Historia de la gente decente de Salta”.
      Colabora con notas en distintos periódicos y diarios del país. Larga y
      reconocida trayectoria periodística. En la actualidad es el Director de
      Bibliotecas y Archivos de la provincia de Salta.
    </p>
    <p>
      25 de noviembre de 1960: Nació en Salta, Alejandro San Millán. Inició su
      actividad política como Secretario de Gobierno de la Municipalidad de
      Chicoana desde 1986 a 1989; en octubre de 1989 fue elegido diputado
      provincial y reelecto en 1993. Fue presidente del Bloque Justicialista
      desde 1993 hasta abril de 1996, fecha en la que es elegido para ocupar la
      Presidencia de la Cámara de Diputados, cargo que ocupa en la actualidad.
    </p>
    <p>
      25 de noviembre de 1990: Falleció el cura párroco de Chicoana, el
      sacerdote Pablo Fernando Acuña.
    </p>
    <p>
      25 de noviembre de 1994: Falleció en Salta, el dirigente justicialista
      Néstor Orellana, quien fue Senador Provincial por el departamento de
      Iruya.
    </p>
    <p>
      25 de noviembre de 1994: Falleció Mario Robustiano “Chuly” Saravia, quien
      fue diputado provincial de Salta, representando al departamento de Anta,
      por la Unión Cívica Radical. Residía en El Quebrachal, este hombre que era
      un gran gaucho y mejor bagualero.
    </p>
    <p>
      26 de noviembre de 1793: Nació en Salta, Facundo de Zuviría. En 1813 se
      doctoró en derecho Romano y derecho Canónico. Fue presidente del Congreso
      Constituyente de Santa Fe, siendo uno de los autores principales del texto
      de la Constitución Nacional de 1853. Murió en Paraná, Entre Ríos el 18 de
      agosto de 1861. El 20 de febrero de 1923 sus restos fueron traídos a Salta
      y depositados en el Panteón de las Glorias del Norte y ese mismo día se
      inauguró el Monumento de Lola Mora en su homenaje en el parque San Martín.
    </p>
    <p>
      26 de noviembre de 1927: Falleció en Buenos Aires, el abogado Abraham
      Echazú. Ejerció la profesión en Salta, donde llegó a ocupar la Presidencia
      de la Corte de Justicia. Fue diputado provincial, ministro de gobierno, en
      la administración de Miguel Ortiz (1881 – 1883) y en la del coronel Juan
      Solá (1883 – 1886). Por invitación del gobernador Leiva de Santa Fe, se
      radicó en esa provincia desempeñando funciones en la justicia y dictando
      cátedras en la Universidad del Litoral.
    </p>
    <p>
      26 de noviembre de 1929: Se inauguró la destilería de Campamento Vespucio,
      en la provincia de Salta.
    </p>
    <p>
      26 de noviembre de 1930: Murió en Tucumán Daniel Giménez Garzón. Escribió:
      Los cuentos de Cabo Cuarto.
    </p>
    <p>
      26 de noviembre de 1939:&nbsp;Nació en Addis – Abeba, Abisinia, Africa,
      Juan Carlos Gottifredi. Doctor en Ingeniería Química que se desempeña como
      docente Universitario e investigador. Pertenece al Consejo Nacional de
      Investigaciones cientificas y técnicas. Fue Rector de la Universidad
      Nacional de Salta. Presidente de la Fundación del Banco del Noroeste. Fue
      miembro fundador y Presidente del Comité Central de Unión Cívica Radical
      de Salta. Fue candidato a gobernador. Ex director del Conicet.
    </p>
    <p>
      26 de noviembre de 1987: Falleció Fray Benito Honorato Pistoia. Fue
      miembro de número y socio fundador del Instituto Güemesiano de Salta. Fue
      director del Instituto G. Tommasini, Colegio secundario de la ciudad de
      Salta. Docente y periodista. Publicó: “Los Franciscanos en el Tucumán”.
    </p>
    <p>
      27 de noviembre de 1926: Nació en Villarrica, Paraguay, Isbelio Godoy.
      Este músico luego de recorrer Paraguay y Brasil, llegó a Salta con su trío
      “Pájaro Campana” en 1950 y se radicó en esta tierra. Tuvo destacada
      actuación en festivales y espectáculos en Salta y en el país. Sus trabajos
      discográficos lograron una importante crítica en todo el país.
    </p>
    <p>
      27 de noviembre de 1949: Se votó por primera vez en Metán para elegir el
      Intendente Municipal. Triunfó el candidato peronista, Gerardo López Lanzi,
      sobre el radical Diógenes Zapata.
    </p>
    <p>
      28 de noviembre de 1817: El Director Supremo Pueyrredón condecora a
      Güemes, sus oficiales y soldados por la victoria obtenida sobre el
      ejército del general La Serna al expulsarlos de la Intendencia de Salta.
    </p>
    <p>
      28 de noviembre de 1940: Nació en la ciudad de Salta, Hugo Alberto
      Giménez. Desde jovense dedicó a la danza argentina. Es el director del
      Ballet Salta, junto a su compañera Marina Tondini. Este ballet recibió el
      premio Santos Vega por la coreografía que realizaron de La Misa Criolla.
      Recorren el país con obras de coreografís como: “La Muerte de Martín
      Güemes”, “La leyenda de la Caá, Yari”, “Romance del Pancho Ramírez”, “La
      Delfina”, “El Cid Americano”, “Miguel de Güemes”, entre otros. Por Europa,
      Estados Unidos, Africa, presentan el espectáculo: “Argentina a puro
      ritmo”.
    </p>
    <p>
      29 de noviembre de 1902: Se fundó el Club Gimnasia y Tiro de Salta en los
      salones del gran Hotel Savoy, que estaba ubicado en Zuviría esquina España
      de la ciudad de Salta. En el Acta fundacional el primer nombre fue Club
      Atlético Salteño. Hubo un presidente provisorio, que fue don Francisco
      Alsina. La primera comisión directiva definida fue presidida por el señor
      José Eustaquio Alderete, su primer presidente.
    </p>
    <p>
      30 de noviembre de 1945: Se creó el Círculo Argentino de Tartagal. Su
      primer presidente fue el doctor Juan María Blanc.
    </p>
    <p>
      30 de noviembre de 1947: Nació en General Güemes, Osvaldo Rubén Salum.
      Abogado que fue Intendente de su ciudad natal y en la actualidad es
      Senador Provincial y Secretario General del Consejo Provincial del Partido
      Justicialista de Salta.
    </p>
    <p>
      30 de noviembre de 1962: Se fundó el Club Social y Deportivo YPF de Metán,
      en la provincia de Salta. Fue un logro del personal del poliducto del
      Distrito zona norte de YPF. Su primer presidente fue el ingeniero Héctor
      Gentille. Participa en campeonatos de Fútbol.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
